import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "gatsby";
import LogoImage from "../images/logo-orange.svg";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className>
      <nav className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  src={LogoImage}
                  alt="Straight Path Ministries"
                  width="200"
                />
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-10 justify-around">
                  <Link
                    to="/"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                  >
                    Home
                  </Link>

                  <Link
                    to="/teaching"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                  >
                    Teaching
                  </Link>

                  <Link
                    to="/mission-trip"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                  >
                    Missions
                  </Link>

                  <Link
                    to="/blog"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold hidden"
                  >
                    News & Blog
                  </Link>

                  <Link
                    to="/about/about-us"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                  >
                    About Us
                  </Link>
                  <Link
                    to="/about/mission-vision"
                    className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                  >
                    Vision
                  </Link>
                  <a
                    class="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-green-300 hover:bg-green-500 text-lg text-gray-900 font-bold  rounded-lg transition duration-200"
                    href="https://www.networkforgood.org/donation/MakeDonation.aspx?ORGID2=223235608&vlrStratCode=sx7lRdmTM3wwLV%2buOkGC4AuWcbnkeA6xhlco3j9N3KUsKEsgryi20AOEgGHBfuKK"
                  >
                    Donate
                  </a>
                  {/* <a
                    class="hidden lg:inline-block py-2 px-6 bg-grey-50 hover:bg-blue-600 text-lg text-gray-900 font-bold rounded-xl transition duration-200"
                    href="#"
                  >
                    Contact
                  </a> */}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div
                ref={ref}
                className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col"
              >
                <Link
                  to="/"
                  className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                >
                  Home
                </Link>

                <Link
                  to="/teaching"
                  className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                >
                  Teaching
                </Link>

                <Link
                  to="/mission-trip"
                  className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                >
                  Missions
                </Link>

                <Link
                  to="/blog"
                  className="text-lg text-gray-900 hover:text-yellow-500 font-bold hidden"
                >
                  News & Blog
                </Link>

                <Link
                  to="/about/about-us"
                  className="text-lg text-gray-900 hover:text-yellow-500 font-bold"
                >
                  About Us
                </Link>
                <a
                  class=" lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-green-300 hover:bg-green-500 text-lg text-gray-900 font-bold  rounded-lg transition duration-200"
                  href="https://www.networkforgood.org/donation/MakeDonation.aspx?ORGID2=223235608&vlrStratCode=sx7lRdmTM3wwLV%2buOkGC4AuWcbnkeA6xhlco3j9N3KUsKEsgryi20AOEgGHBfuKK"
                >
                  Donate
                </a>
                {/* <a
                    class="hidden lg:inline-block py-2 px-6 bg-grey-50 hover:bg-blue-600 text-lg text-gray-900 font-bold rounded-xl transition duration-200"
                    href="#"
                  >
                    Contact
                  </a> */}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}

export default Nav;
